body {
	color: var(--color-base);
	font-weight: var(--font-weight-base);
}


h1, h2, h3, h4, h5 {
	font-weight: var(--font-weight-prominent);
	margin: 1rem 0;
	& a {
		font-weight: var(--font-weight-base) !important;
	}
}

h1 {
	font-size: 2rem;
}

h2 {
	font-size: 1.5rem;
}

h3 {
	font-size: 1.2rem;
}
h2, h3 {
	margin: 2rem 0 1rem;
}
h4, h5 {
	font-weight: 500;
}

section {
	margin-bottom: 2rem;
}

.headline--first {
	margin-top: 1rem;
}

.break-all {
	word-break: break-all;
}
@mixin default-links-interacted() {
	&:hover,
	&:focus {
		color: var(--color-primary-accent);
	}
	&:focus {
		outline: 1px solid var(--color-primary-accent);
	}

	/** @deprecated Use --active */
	&.active {
		color: var(--color-primary);
		svg {
			fill: var(--color-primary);
		}
	}
	&--active {
		color: var(--color-primary);
		svg {
			fill: var(--color-primary);
		}
	}
}

a,
a:visited {
	font-weight: 400;
	color: var(--color-primary);
	text-decoration: none;
	@include default-links-interacted();
}
a.btn {
	display: inline-block;
}


.ellipsis {
	overflow-x: hidden;
	text-overflow: ellipsis;
	word-break: keep-all;
	white-space: nowrap;
}

.ellipsis-parent {
	max-width: 100%;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-muted {
	color: var(--color-base-muted);
	font-size: small;
}

.text-small {
	font-size: small;
}

.text-nowrap {
	white-space: nowrap;
}

.pull-right {
	float: right;
}

pre {
	background: var(--color-floor);
	padding: 0.5em;
	word-break: break-all;
	white-space: normal;
}
