$transition-speed-normal: 0.3s;
$form-text-padding: 0.5em;

$screen-micro: 400px !default;
$screen-small: 780px !default;
$screen-medium: 1000px !default;

$screen-micro-over: $screen-micro + 1px;
$screen-small-over: $screen-small + 1;
$screen-medium-over: $screen-medium + 1;

$breakpoint-bottom-navigation-max: $screen-medium;
$breakpoint-bottom-navigation-max-over: $screen-medium-over;

$default-svg-width: 1.3em;

$z-navigation: 1000;

$maxPageWidth: 63em;

:root {
  --color-base: #000;
  --color-contrast: white;
  --color-base-muted: #545454;
  --color-background: #fff;
  --color-background-card: #fff;
  --color-primary: #0c5db9;
  --color-primary-accent: #0f75e9;
  --color-primary-light: #0e69d1;
  --color-primary-translucent: rgba(12,93,185, 0.9);
  --color-input: var(--color-base);
  --color-input-background: var(--color-background);
  --color-separator: #ededed;
  --color-separator-dark: #D3D3D3;
  --color-separator-darker: #c7c7c7;
  --color-button-background: #eaeaea;
  --color-button-background-focus: #c3c3c3;
  --color-floor: #f4f4f4;
  --color-odd: #f4f4f4;
  --color-brand: #f34541;
  --color-brand-translucent: rgba(243,69,85, 0.9);
  --color-success: green;
  --color-warning: #ea722b;
  --color-warning-dark: #e56317;
  --color-dot-green: #49bf67;
  --color-dot-red: #F34541;

  --border-radius-base: 4px;

  --box-shadow-card: 0 0px 1px rgba(0, 0, 0, 0.95);
  --footer-shadow: inset 0 2px 4px -2px var(--color-separator-darker);
  --hero-background: linear-gradient(#0f75e9,#0e69d1);
  --tooltip-background: rgba(255,255,255, 0.7);
  --default-svg-color: 333333;
  --btn-primary-text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
  --color-dot-green-highlight: #6fcc87;
  --color-dot-red-highlight: f38041;

  --btn-box-shadow: none;
  --btn-box-shadow-focus: none;

  --btn-primary-box-shadow: 0 0px 1px var(--color-base);
  --btn-primary-box-shadow-focus: var(--btn-primary-box-shadow);

  --navigation-background: var(--color-background);
  --navigation-background-transparent: rgba(255, 255, 255, 0.8);

  --font-weight-base: 400;
  --font-weight-prominent: 600;
  --font-weight-muted: 300;
}

@media (prefers-color-scheme: dark) {
  :root {
    // Will stay
    --color-background: #212122;
    --color-base: #e0e0e0;
    --color-contrast: white;
    --color-base-muted: #ababab;
    --color-background-card: #3e3e3c;
    --color-primary: #1d94cb;
    --color-primary-light: #0e69d1;
    --color-primary-accent: #28b3e1;
    --color-primary-translucent: rgba(29,148,203, 0.9);
    --color-input: var(--color-base);
    --color-input-background: var(--color-background-card);
    --color-separator: #313131;
    --color-separator-dark: #575757;
    --color-separator-darker: #565656;
    --color-button-background: #787878;
    --color-button-background-focus: #898989;
    --color-floor: #141414;
    --color-odd: #0d0d0d;
    --color-brand: #f34541;
    --color-brand-translucent: rgba(243,69,85, 0.9);
    --color-success: green;
    --color-warning: #ea722b;
    --color-warning-dark: #e56317;
    --color-dot-green: #49bf67;
    --color-dot-red: #F34541;

    --box-shadow-card: 0 -1px 1px 0px rgba(80, 80, 80, 1);
    --footer-shadow: none;
    --hero-background: linear-gradient(#0f75e9,#0e69d1);
    --tooltip-background: rgba(0,0,0,0.3);
    --default-svg-color: var(--color-base);
    --btn-primary-text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2);
    --color-dot-green-highlight: #6fcc87;
    --color-dot-red-highlight: f38041;
    --btn-box-shadow: 0 -1px 1px 0px rgba(158, 158, 158, 1);
    --btn-box-shadow-focus: var(--btn-box-shadow);
    --btn-primary-box-shadow: 0 -1px 1px 0px #0f86fc;
    --btn-primary-box-shadow-focus: 0 -1px 1px 0px #0f86fc;
    --navigation-background: var(--color-background);
    --navigation-background-transparent: rgba(33, 33, 34, 0.69);
  }
}
