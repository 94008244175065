:root {
  --color-base: #000;
  --color-contrast: white;
  --color-base-muted: #545454;
  --color-background: #fff;
  --color-background-card: #fff;
  --color-primary: #0c5db9;
  --color-primary-accent: #0f75e9;
  --color-primary-light: #0e69d1;
  --color-primary-translucent: #0c5db9e6;
  --color-input: var(--color-base);
  --color-input-background: var(--color-background);
  --color-separator: #ededed;
  --color-separator-dark: #d3d3d3;
  --color-separator-darker: #c7c7c7;
  --color-button-background: #eaeaea;
  --color-button-background-focus: #c3c3c3;
  --color-floor: #f4f4f4;
  --color-odd: #f4f4f4;
  --color-brand: #f34541;
  --color-brand-translucent: #f34555e6;
  --color-success: green;
  --color-warning: #ea722b;
  --color-warning-dark: #e56317;
  --color-dot-green: #49bf67;
  --color-dot-red: #f34541;
  --border-radius-base: 4px;
  --box-shadow-card: 0 0px 1px #000000f2;
  --footer-shadow: inset 0 2px 4px -2px var(--color-separator-darker);
  --hero-background: linear-gradient(#0f75e9, #0e69d1);
  --tooltip-background: #ffffffb3;
  --default-svg-color: 333333;
  --btn-primary-text-shadow: 0px 0px 1px #0003;
  --color-dot-green-highlight: #6fcc87;
  --color-dot-red-highlight: f38041;
  --btn-box-shadow: none;
  --btn-box-shadow-focus: none;
  --btn-primary-box-shadow: 0 0px 1px var(--color-base);
  --btn-primary-box-shadow-focus: var(--btn-primary-box-shadow);
  --navigation-background: var(--color-background);
  --navigation-background-transparent: #fffc;
  --font-weight-base: 400;
  --font-weight-prominent: 600;
  --font-weight-muted: 300;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #212122;
    --color-base: #e0e0e0;
    --color-contrast: white;
    --color-base-muted: #ababab;
    --color-background-card: #3e3e3c;
    --color-primary: #1d94cb;
    --color-primary-light: #0e69d1;
    --color-primary-accent: #28b3e1;
    --color-primary-translucent: #1d94cbe6;
    --color-input: var(--color-base);
    --color-input-background: var(--color-background-card);
    --color-separator: #313131;
    --color-separator-dark: #575757;
    --color-separator-darker: #565656;
    --color-button-background: #787878;
    --color-button-background-focus: #898989;
    --color-floor: #141414;
    --color-odd: #0d0d0d;
    --color-brand: #f34541;
    --color-brand-translucent: #f34555e6;
    --color-success: green;
    --color-warning: #ea722b;
    --color-warning-dark: #e56317;
    --color-dot-green: #49bf67;
    --color-dot-red: #f34541;
    --box-shadow-card: 0 -1px 1px 0px #505050;
    --footer-shadow: none;
    --hero-background: linear-gradient(#0f75e9, #0e69d1);
    --tooltip-background: #0000004d;
    --default-svg-color: var(--color-base);
    --btn-primary-text-shadow: 0px 0px 1px #0003;
    --color-dot-green-highlight: #6fcc87;
    --color-dot-red-highlight: f38041;
    --btn-box-shadow: 0 -1px 1px 0px #9e9e9e;
    --btn-box-shadow-focus: var(--btn-box-shadow);
    --btn-primary-box-shadow: 0 -1px 1px 0px #0f86fc;
    --btn-primary-box-shadow-focus: 0 -1px 1px 0px #0f86fc;
    --navigation-background: var(--color-background);
    --navigation-background-transparent: #212122b0;
  }
}

body {
  color: var(--color-base);
  font-weight: var(--font-weight-base);
}

h1, h2, h3, h4, h5 {
  font-weight: var(--font-weight-prominent);
  margin: 1rem 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a {
  font-weight: var(--font-weight-base) !important;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

h2, h3 {
  margin: 2rem 0 1rem;
}

h4, h5 {
  font-weight: 500;
}

section {
  margin-bottom: 2rem;
}

.headline--first {
  margin-top: 1rem;
}

.break-all {
  word-break: break-all;
}

a, a:visited {
  color: var(--color-primary);
  font-weight: 400;
  text-decoration: none;
}

a:hover, a:focus, a:visited:hover, a:visited:focus {
  color: var(--color-primary-accent);
}

a:focus, a:visited:focus {
  outline: 1px solid var(--color-primary-accent);
}

a.active, a:visited.active {
  color: var(--color-primary);
}

a.active svg, a:visited.active svg {
  fill: var(--color-primary);
}

a--active, a:visited--active {
  color: var(--color-primary);
}

a--active svg, a:visited--active svg {
  fill: var(--color-primary);
}

a.btn {
  display: inline-block;
}

.ellipsis {
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  overflow-x: hidden;
}

.ellipsis-parent {
  max-width: 100%;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-muted {
  color: var(--color-base-muted);
  font-size: small;
}

.text-small {
  font-size: small;
}

.text-nowrap {
  white-space: nowrap;
}

.pull-right {
  float: right;
}

pre {
  background: var(--color-floor);
  word-break: break-all;
  white-space: normal;
  padding: .5em;
}

:root {
  --color-base: #000;
  --color-contrast: white;
  --color-base-muted: #545454;
  --color-background: #fff;
  --color-background-card: #fff;
  --color-primary: #0c5db9;
  --color-primary-accent: #0f75e9;
  --color-primary-light: #0e69d1;
  --color-primary-translucent: #0c5db9e6;
  --color-input: var(--color-base);
  --color-input-background: var(--color-background);
  --color-separator: #ededed;
  --color-separator-dark: #d3d3d3;
  --color-separator-darker: #c7c7c7;
  --color-button-background: #eaeaea;
  --color-button-background-focus: #c3c3c3;
  --color-floor: #f4f4f4;
  --color-odd: #f4f4f4;
  --color-brand: #f34541;
  --color-brand-translucent: #f34555e6;
  --color-success: green;
  --color-warning: #ea722b;
  --color-warning-dark: #e56317;
  --color-dot-green: #49bf67;
  --color-dot-red: #f34541;
  --border-radius-base: 4px;
  --box-shadow-card: 0 0px 1px #000000f2;
  --footer-shadow: inset 0 2px 4px -2px var(--color-separator-darker);
  --hero-background: linear-gradient(#0f75e9, #0e69d1);
  --tooltip-background: #ffffffb3;
  --default-svg-color: 333333;
  --btn-primary-text-shadow: 0px 0px 1px #0003;
  --color-dot-green-highlight: #6fcc87;
  --color-dot-red-highlight: f38041;
  --btn-box-shadow: none;
  --btn-box-shadow-focus: none;
  --btn-primary-box-shadow: 0 0px 1px var(--color-base);
  --btn-primary-box-shadow-focus: var(--btn-primary-box-shadow);
  --navigation-background: var(--color-background);
  --navigation-background-transparent: #fffc;
  --font-weight-base: 400;
  --font-weight-prominent: 600;
  --font-weight-muted: 300;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #212122;
    --color-base: #e0e0e0;
    --color-contrast: white;
    --color-base-muted: #ababab;
    --color-background-card: #3e3e3c;
    --color-primary: #1d94cb;
    --color-primary-light: #0e69d1;
    --color-primary-accent: #28b3e1;
    --color-primary-translucent: #1d94cbe6;
    --color-input: var(--color-base);
    --color-input-background: var(--color-background-card);
    --color-separator: #313131;
    --color-separator-dark: #575757;
    --color-separator-darker: #565656;
    --color-button-background: #787878;
    --color-button-background-focus: #898989;
    --color-floor: #141414;
    --color-odd: #0d0d0d;
    --color-brand: #f34541;
    --color-brand-translucent: #f34555e6;
    --color-success: green;
    --color-warning: #ea722b;
    --color-warning-dark: #e56317;
    --color-dot-green: #49bf67;
    --color-dot-red: #f34541;
    --box-shadow-card: 0 -1px 1px 0px #505050;
    --footer-shadow: none;
    --hero-background: linear-gradient(#0f75e9, #0e69d1);
    --tooltip-background: #0000004d;
    --default-svg-color: var(--color-base);
    --btn-primary-text-shadow: 0px 0px 1px #0003;
    --color-dot-green-highlight: #6fcc87;
    --color-dot-red-highlight: f38041;
    --btn-box-shadow: 0 -1px 1px 0px #9e9e9e;
    --btn-box-shadow-focus: var(--btn-box-shadow);
    --btn-primary-box-shadow: 0 -1px 1px 0px #0f86fc;
    --btn-primary-box-shadow-focus: 0 -1px 1px 0px #0f86fc;
    --navigation-background: var(--color-background);
    --navigation-background-transparent: #212122b0;
  }
}

html, body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 100%;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

html {
  background-color: var(--color-floor);
}

body {
  max-width: 100vw;
}

* {
  box-sizing: border-box;
}

.root-container, main {
  max-width: 63em;
  width: 100%;
  box-sizing: border-box;
  margin: 1rem auto;
  padding: 0 1em;
}

@media (max-width: 63em) {
  .root-container, main {
    max-width: 100vw;
  }
}

.page {
  width: 100%;
  float: left;
  background: var(--color-background);
}

.page--simple-centered {
  display: flex;
}

@media screen and (min-width: 780px) {
  .page--simple-centered .card {
    max-width: 50rem;
  }
}

.page--simple-centered main {
  max-width: 50em;
}

.row {
  flex-flow: wrap;
  display: flex;
}

.row.row--centered {
  justify-content: center;
}

.row.row--align-middle {
  align-content: center;
  align-items: center;
}

.row.row--no-wrap {
  flex-flow: row;
}

.row .col-1 {
  width: 8.25%;
}

.row .col-2 {
  width: 16.5%;
}

.row .col-3 {
  width: 25%;
}

.row .col-4 {
  width: 33%;
}

.row .col-5 {
  width: 41.25%;
}

.row .col-6 {
  width: 50%;
}

.row .col-7 {
  width: 58.25%;
}

.row .col-8 {
  width: 66%;
}

.row .col-9 {
  width: 75%;
}

.row .col-10 {
  width: 82.5%;
}

.row .col-11 {
  width: 91.75%;
}

.row .col-12 {
  width: 100%;
}

.row .col--align-middle {
  align-items: center;
  display: flex;
}

.flex {
  display: flex;
}

.flex--stretch {
  align-items: stretch;
}

.flex--space-between {
  justify-content: space-between;
}

.flex--column {
  flex-direction: column;
}

.flex--align-center {
  align-items: center;
}

@media screen and (max-width: 780px) {
  body {
    padding-bottom: 4em;
  }

  .row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10 {
    width: 100%;
  }

  .flex--column-sm {
    flex-direction: column;
  }

  .flex--column-reverse-sm {
    flex-direction: column-reverse;
  }
}

.hidden {
  display: none;
}

.sad, .sad-container {
  background: var(--color-floor);
  border: 1px dashed var(--color-base-muted);
}

.sad-container {
  padding-top: .5em;
  padding-bottom: .5em;
}

.grid {
  gap: 1rem;
  display: grid;
}

.grid--with-margin-bottom {
  margin-bottom: 2rem;
}

.grid--v-center {
  align-items: center;
}

.grid--2cols {
  grid-template-columns: 1fr 1fr;
}

.grid--3cols {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid--cols-3-9 {
  grid-template-columns: 3fr 9fr;
}

@media screen and (max-width: 780px) {
  .grid--2cols, .grid--3cols {
    grid-template-columns: 1fr;
  }
}

/*# sourceMappingURL=index.af84834f.css.map */
