@import './variables.scss';

html, body {
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
  background-color: var(--color-floor);
}

body {
  max-width: 100vw;
}

* {
  box-sizing: border-box;
}

.root-container {
  max-width: $maxPageWidth;
  margin: 1rem auto;
  padding: 0 1em;
  width: 100%;
  box-sizing: border-box;;

  @media (max-width: $maxPageWidth) {
    // When min/max/clamp is better adopted: max-width: min(100vw, 63em);
    max-width: 100vw;
  }
}

main {
  @extend .root-container;
}

.page {
  width: 100%;
  float: left;
  background: var(--color-background);

  &--simple-centered {
    display: flex;

    @media screen and (min-width: $screen-small) {
      .card {
        max-width: 50rem;
      }
    }

    main {
      max-width: 50em;
    }
  }
}

.row {
  display: flex;
  flex-flow: row wrap;

  &.row--centered {
    justify-content: center;
  }

  &.row--align-middle {
    align-content: center;
    align-items: center;
  }

  &.row--no-wrap {
    flex-flow: row;
  }

  .col-1 {
    width: 8.25%;
  }

  .col-2 {
    width: 16.5%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33%;
  }

  .col-5 {
    width: 41.25%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.25%;
  }

  .col-8 {
    width: 66%
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 82.5%;
  }

  .col-11 {
    width: 91.75%;
  }

  .col-12 {
    width: 100%;
  }

  .col--align-middle {
    display: flex;
    align-items: center;
  }
}

.flex {
  display: flex;
}

.flex--stretch {
  align-items: stretch;
}

.flex--space-between {
  justify-content: space-between;
}

.flex--column {
  flex-direction: column;
}

.flex--align-center {
  align-items: center;
}

@media screen and (max-width: $screen-small) {
  body {
    padding-bottom: 4em;
  }

  .row {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10 {
      width: 100%;
    }
  }

  .flex--column-sm {
    flex-direction: column;
  }

  .flex--column-reverse-sm {
    flex-direction: column-reverse;
  }
}

.hidden {
  display: none;
}

.sad {
  background: var(--color-floor);
  border: 1px dashed var(--color-base-muted);
}

.sad-container {
  @extend .sad;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

}

.grid {
  display: grid;
  gap: 1rem;

  &--with-margin-bottom {
    margin-bottom: 2rem;
  }

  &--v-center {
    align-items: center;
  }

  &--2cols {
    grid-template-columns: 1fr 1fr;
  }

  &--3cols {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &--cols-3-9 {
    grid-template-columns: 3fr 9fr;
  }

  @media screen and (max-width: $screen-small) {
    &--2cols,
    &--3cols {
      grid-template-columns: 1fr;
    }
  }
}
